import {router} from "./routes";
import {RouterProvider} from "react-router-dom";
import React from "react";
import {Fallback} from "./AppRouter";

const App = () => {
  return (
    <div className="App">
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </div>
  );
};

export default App;
