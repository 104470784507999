import {useCornListStore} from "../contexts/CornStore";
import {useEffect} from "react";

export default function CornHome() {
  // eslint-disable-next-line no-console
  console.log('home')
  const {list, fetchData, isLoading, isLoaded, isError, error} = useCornListStore()

  useEffect(() => {
    if (!list.length && !isLoaded && !isLoading) {
      fetchData()
    }
  }, [list, isLoading, isLoaded, fetchData])
// eslint-disable-next-line no-console
  console.log(list, 'list')
  if (isLoading) {
    return <div>I am loading</div>
  }

  if (isError) {
    return <div>{error}</div>
  }

  if (isLoaded) {
    if (!list?.length) return null

    return (
      <ul className="my-0 mx-auto flex items-center p-2 flex-wrap grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {list.map(item => {
          const {id, attributes: {
            name, sex, birthday, arrived,avatar: {data: avatarData}
          }} = item
          const avatarUrl = avatarData ? 'https://api.vicia.net' + avatarData.attributes.url : 'https://pic1.zhimg.com/v2-eb9fc308782cf6b01329ac33ffd2b113_xl.jpg'
          return (
            <li key={id} className="flex flex-shrink-0 m-3 p-3 border rounded">
              <img className="w-20 h-20 rounded-full" src={avatarUrl} alt="" />
              <section className="flex flex-col ml-5">
                <div className="flex items-center">
                  <h3 className={`text-xl font-semibold ${sex ? 'text-blue-500' : 'text-red-500'} whitespace-nowrap`}>{name}</h3>
                  {birthday && <span className="mx-2 text-sm whitespace-nowrap">🎂 {birthday}</span>}
                </div>
                <p className="my-2 leading-[20px]">
                  {arrived && <span className="text-sm whitespace-nowrap">🏠 {arrived}</span>}
                </p>
                <p className="leading-[20px]">Morphs</p>
              </section>
            </li>
          )
        })}
      </ul>
    )
  }

  return null
}
