// xaxis 使用 date 的示例：https://codesandbox.io/s/recharts-area-chart-with-date-axis-6o55k?file=/src/DateArea.js

export default function CornProfile() {
  return (
    <div className="flex flex-col p-5">
      <header className="flex pb-5 border-b">
        <img className="w-24 h-24 rounded-full" src="https://pic1.zhimg.com/v2-eb9fc308782cf6b01329ac33ffd2b113_xl.jpg?source=32738c0c" alt="" />
        <section className="flex flex-col ml-5">
          <p className="text-2xl font-semibold">Name</p>
          <p className="my-2.5 leading-[22px]">
            <span>Sign</span>
            <span className="mx-2">Birthday</span>
            <span>Arrived</span>
          </p>
          <p className="leading-[22px]">Genetics</p>
        </section>
      </header>
      <ul className="flex my-5">
        <li className="flex shrink-0 flex-1">
          <img className="w-10 h-10" src="https://pic1.zhimg.com/v2-eb9fc308782cf6b01329ac33ffd2b113_xl.jpg?source=32738c0c" alt="" />
          <span className="ml-2.5">Days</span>
        </li>
        <li className="flex shrink-0 flex-1">
          <img className="w-10 h-10" src="https://pic1.zhimg.com/v2-eb9fc308782cf6b01329ac33ffd2b113_xl.jpg?source=32738c0c" alt="" />
          <span className="ml-2.5">Days</span>
        </li>
      </ul>
      <section>
        Chart
      </section>
      <section>
        Table
      </section>
    </div>
  )
}

