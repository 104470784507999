import {create} from "zustand";
import {produce} from "immer";
import {asyncFetchData} from "../lib/api";

export const useCornListStore = create((set) => ({
  list: [],
  error: '',
  isLoading: false,
  isError: false,
  isLoaded: false,
  fetchData: async () => {
    try {
      set({ isLoading: true });
      const response = await asyncFetchData({
        path: 'corns',
        query: 'populate=*&sort[0]=createdAt%3Aasc',
      })
      set({ list: response.data, isLoading: false, isLoaded: true });
    } catch (error) {
      set({ error, isError: true, isLoading: false });
    }
  },
}))
