import {createBrowserRouter} from "react-router-dom";
import {
  cornsLoader,
  deferredLoader,
  DeferredPage,
  Layout,
  Todo,
  todoLoader,
  todosAction,
  TodosBoundary,
  TodosList,
  todosLoader
} from "./AppRouter";
import CornProfile from './pages/CornProfile'
import CornHome from './pages/CornHome'

export let router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        loader: cornsLoader,
        Component: CornHome,
        // loader: homeLoader,
        // Component: Home,
      },
      {
        path: "todos",
        action: todosAction,
        loader: todosLoader,
        Component: TodosList,
        ErrorBoundary: TodosBoundary,
        children: [
          {
            path: ":id",
            loader: todoLoader,
            Component: Todo,
          },
        ],
      },
      {
        path: "deferred",
        loader: deferredLoader,
        Component: DeferredPage,
      },
      {
        path: "profile",
        Component: CornProfile
      }
    ],
  },
]);
