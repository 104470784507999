// Parses the JSON returned by a network request
export const parseJSON = (resp) => (resp.json ? resp.json() : resp);

// Checks if a network request came back fine, and throws an error if not
export const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

export const headers = { "Content-Type": "application/json" };

// export const BASE_URL = 'http://localhost:1337/api'
export const BASE_URL = 'https://api.vicia.net/api'

// fixme: better query
export const fetchData = ({path, id, query, options = {}, onSuccess, onError}) => {
  fetch(`${BASE_URL}/${path}${id ? `/${id}` : ''}${query ? `?${query}` : ""}`, { headers, method: "GET", ...options })
    .then(checkStatus)
    .then(parseJSON)
    .then(({ data }) => {
      // eslint-disable-next-line no-console
      console.log(data)
      onSuccess(data)
    })
    .catch(onError);
}

export const postData = async ({path, data}) => {
  let result;
  await fetch(`${BASE_URL}/${path}`, {
    headers,
    method: "POST",
    body: JSON.stringify({ data }),
  }).then(checkStatus)
    .then(parseJSON)
    .then(({ data }) => {
      // eslint-disable-next-line no-console
      console.log(data, 'data')
      result =  {
        status: 1,
        data,
      }
    })
    .catch(err => {
      result = {
        status: 0,
        err
      }
    });
  return result
}

export const postFileData = async ({path, formData}) => {
  let result;
  await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: formData,
  }).then(checkStatus)
    .then(parseJSON)
    .then((res) => {
      // eslint-disable-next-line no-console
      console.log(res, 'res')
      result =  {
        status: 1,
        data: res[0].data,
      }
    })
    .catch(err => {
      result = {
        status: 0,
        err
      }
    });
  return result
}

export const postFormData = async ({path, formData}) => {
  let result;
  await fetch(`${BASE_URL}/${path}`, {
    method: "POST",
    body: {data: formData},
    headers: {"Content-Type": "multipart/form-data" }
  }).then(checkStatus)
    .then(parseJSON)
    .then((res) => {
      // eslint-disable-next-line no-console
      console.log(res, 'res')
      result =  {
        status: 1,
        data: res[0].data,
      }
    })
    .catch(err => {
      result = {
        status: 0,
        err
      }
    });
  return result
}

export const combineData = async ({path, id, type, connectData, onSuccess, onError}) => {
  await fetch(`${BASE_URL}/${path}/${id}`, {
    headers,
    method: "PUT",
    body: JSON.stringify({ data: {
      [`${type}s`]: {
        connect: connectData
      }
    }}),
  })
    .then(onSuccess)
    .catch(onError);
}

export const addNewAction = async ({cornId = 1, type = 'cleaning', date = new Date(Date.now()), ...extra }) => {
  const result = await postData({
    path: `${type}s`,
    data: {
      date,
      ...extra
    },
  })
  if (result?.status && result?.data?.id) {
    await combineData({
      path: 'corns',
      id: cornId,
      type,
      connectData: [{id: result.data.id}]
    }).then(() => {
      // eslint-disable-next-line no-console
      console.log('yeah!')
    })
  } else {
    // eslint-disable-next-line no-console
    console.log(result, 'oh no')
  }
}

export const addNewGo = async ({date = new Date(Date.now()), name, file }) => {
  postData({
    path: 'gos',
    data: {
      date,
      name
    }
  }).then(result => {
    if (result?.status && result?.data?.id) {

      const formData = new FormData();
      formData.append('files', file);
      formData.append("ref", "api::go.go");
      formData.append("refId", result.data.id);
      formData.append("field", "file")
      postFileData({
        path: 'upload',
        formData
      })
    } else {
      // eslint-disable-next-line no-console
      console.log(result, 'oh no')
    }
  })
}

export async function asyncFetchData({path, id, query, options = {}}) {
  let result
  await fetch(`${BASE_URL}/${path}${id ? `/${id}` : ''}${query ? `?${query}` : ""}`, {
    headers,
    method: "GET", ...options
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(({data}) => {
      result = {
        status: 1,
        data
      }
    })
    .catch(err => {
      result = {
        status: 0,
        err
      }
    });
  return result
}
